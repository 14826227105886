<template>
  <div class="complaints">
    <Header></Header>
    <div class="complaints-content">
      <div class="content-top">
        <span>{{ item.msg }}</span>
        <span>COMPLAINTS</span>
      </div>
      <div v-html="item.data"></div>
    </div>
    <div class="link-me">
      <div>留言给我们</div>
      <div class="link-form">
        <div class="link-item">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <div style="display: flex">
              <el-form-item prop="name">
                <el-input placeholder="请输入您的姓名" v-model="ruleForm.name">
                </el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input placeholder="请输入联系方式" v-model="ruleForm.phone">
                </el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-input type="textarea" v-model="ruleForm.desc"></el-input>
            </el-form-item>

            <el-form-item>
              <el-upload
                class="upload-demo"
                action="http://api.zbzyuan.com/api/weiquan/upload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="unploadSussess"
                :before-remove="beforeRemove"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="fileList"
                style="display: inline-block"
              >
                <el-button type="info">文件上传</el-button>
              </el-upload>
              <el-button type="primary" @click="Submit">确认提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/mobile/Header.vue";
import Footer from "../../../components/mobile/Footer.vue";
export default {
  name: "Complaints",
  data() {
    return {
      content: "",
      item: "",
      fileUrl: "",
      fileList: [],
      filetype: "",
      ruleForm: {
        name: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          { min: 2, max: 5, message: "名字长度在2-5个字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "您输入的手机号码位数不对",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        url: "/weiquan/index",
      }).then((res) => {
        console.log(res, "res");
        this.item = res;
      });
    },
    Submit() {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(this.fullurl)) {
        this.filetype = 1;
      } else {
        this.filetype = 0;
      }

      if (this.ruleForm.name && this.ruleForm.phone) {
        let Tphone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!Tphone.test(this.ruleForm.phone)) {
          console.log("手机号不正确");
          this.$message({
            message: "输入正确的手机号",
            type: "warning",
          });
        } else {
          console.log(this.ruleForm);
          this.$http({
            url: "/weiquan/common",
            method: "POST",
            data: {
              ...this.ruleForm,
              filename: this.fileUrl,
              filetype: this.filetype,
            },
          }).then((res) => {
            console.log(res, "Submit");

            if (res.code == 1) {
              this.ruleForm = {
                name: "",
                phone: "",
                content: "",
              };
              (this.fileUrl = ""), (this.fileList = []);
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        }
      } else {
        this.$message({
          message: "请填写您的信息",
          type: "warning",
        });
      }
    },
    unploadSussess(response) {
      console.log(response);
      if (response.code == 1) {
        this.fileUrl = response.data.fullurl;
        this.$message({
          message: response.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: response.msg,
          type: "warning",
        });
        this.fileUrl = "";
        this.fileList = [];
      }

      console.log(this.fileUrl);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
}
/deep/.el-input {
  width: 300px !important;
  margin-right: 75px;
  height: 36px !important;
}
/deep/.el-button {
  height: 36px !important;
  line-height: 6px !important;
  margin-left: 100px;
  margin-top: -50px !important;
}
/deep/.el-input__inner {
  height: 45px !important;
}
/deep/.el-textarea__inner {
  width: 690px !important;
  height: 120px !important;
}
/deep/.el-form-item__content {
  margin-left: 0 !important;
}
/deep/.el-textarea {
  width: 690px !important;
  height: 184px !important;
}
/deep/.el-upload-list__item-name {
  margin-bottom: -55px;
}
/deep/.el-upload-list__item:first-child {
  margin-top: -17px;
}
/deep/.el-upload-list__item:nth-child(n + 1) {
  margin-top: -17px;
}
/deep/.el-message-box{
  width: 382px!important;
}

/deep/.el-upload-list__item-name{
  width: 255px!important;
}

.complaints {
  .complaints-content {
    width: 690px;
    margin: 40px auto;
    .content-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 59px;
      color: #333333;
      > span:nth-child(1) {
        font-size: 28px;
      }
      > span:nth-child(2) {
        font-size: 20px;
        transform: scale(0.633333);
      }
    }
    > div:nth-child(2) {
      margin-bottom: 39px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    > div:nth-child(3) {
      margin-bottom: 40px;
      font-size: 20px;
      color: #333333;
    }
    > div:nth-child(4) {
      margin-bottom: 39px;
      font-size: 20px;
      color: #333333;
      > div:nth-child(2) {
        margin-top: 5px;
        display: flex;
        > span:nth-child(1) {
          display: block;
          white-space: nowrap;
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          > span {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .link-me {
    width: 750px;
    > div:nth-child(1) {
      width: 690px;
      margin: 20px auto 31px;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .link-form {
      width: 750px;
      height: 388px;
      margin-bottom: 50px;
      background-color: rgb(234, 238, 244);
      overflow: hidden;
      .link-item {
        width: 690px;
        margin: 0 auto;
      }
    }
  }
}
</style>